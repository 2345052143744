import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Heading, Map } from '../../components';
import css from './ListingPage.module.css';
import { debounce } from 'lodash';

const SectionMapMaybe = ({
  className,
  rootClassName,
  geolocation,
  publicData,
  listingId,
  mapsConfig,
}) => {
  const [isStatic, setIsStatic] = useState(true);
  const [pageWidth, setPageWidth] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;
      const width = window.outerWidth;
      const adjustedWidth = Math.round(width - width * 0.15);
      setPageWidth(isMobile ? adjustedWidth : null);
    };
    handleResize();
    const debouncedResize = debounce(handleResize, 300);
    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, []);

  if (!geolocation) {
    return null;
  }

  const address = publicData && publicData.location ? publicData.location.address : '';
  const classes = classNames(rootClassName || css.sectionMap, className);
  const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;
  const mapProps = mapsConfig.fuzzy.enabled
    ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, mapsConfig.fuzzy.offset, cacheKey) }
    : { address, center: geolocation };

  const map = <Map {...mapProps} useStaticMap={isStatic} pageWidth={pageWidth} />;

  return (
    <section className={classes} id="listing-location">
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.locationTitle" />
      </Heading>
      {isStatic ? (
        <button
          className={css.map}
          onClick={() => {
            setIsStatic(false);
          }}
        >
          {map}
        </button>
      ) : (
        <div className={css.map}>{map}</div>
      )}
    </section>
  );
};

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
